import React, { useEffect } from 'react';
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { CSVLink } from "react-csv";

import Multiselect from 'multiselect-react-dropdown';
import { getActiveClientList } from 'services/client';
import CustomSelect from 'utils/CustomSelect';
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import { getMembershipNewReport } from 'services/reports';

const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);

const animatedComponents = makeAnimated();
export default function MembershipReportNew() {
    const [dealers, setDealers] = React.useState([{}]);
    const [selectedDealers, setSelectedDealers] = React.useState([])
    const [membershipReport, setMembershipReport] = React.useState(null)
    const [filtered, setFiltered] = React.useState(false)
    const [date, setDate] = React.useState()
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDownloading, setIsDownloading] = React.useState(false);

    useEffect(() => {
        getActiveClientList().then((response) => {
            const { data } = response
            const formattedData = data.map(item => {
                return {
                    value: item.id,
                    label: item.id
                }
            })
            setDealers(formattedData);
        }).catch((error) => {
        });
    }, []);

    useEffect(() => {
        if (!membershipReport) return
        setFiltered(true)
    }, [membershipReport])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (selectedDealers.length < 1) {
            alert("Lista di Users is empty")
        }
        else {
            setIsLoading(true); // Start loading
            setIsDownloading(false); // Reset isDownloading
            const selectedDealersIds = selectedDealers.map(dealer => dealer.value)
            getMembershipNewReport(selectedDealersIds, date)
                .then(res => {
                    setMembershipReport(res.data);
                    setIsLoading(false); // Stop loading
                })
                .catch(err => {
                    setIsLoading(false); // Stop loading in case of an error
                })
        }
    }
    const handleChange = (item) => {
        setSelectedDealers(item)
    }

    return (
        <div>
            <div>
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <Card className="form-wrapper mt-4">
                                <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                                    <Card.Title as="h4" className="text-center m-3 heading">
                                        Membership Report
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label className="requiredelement">Dealers</label>
                                                    <CustomSelect
                                                        options={dealers} // Options to display in the dropdown
                                                        // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{ Option, MultiValue, animatedComponents }}
                                                        onChange={handleChange}
                                                        allowSelectAll={true}
                                                        value={selectedDealers}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label className="requiredelement">Date</label>
                                                    <Form.Control
                                                        required
                                                        placeholder="abcd"
                                                        type="month"
                                                        name="Title"
                                                        onChange={(e) => {
                                                            setDate(e.target.value)
                                                        }}
                                                    ></Form.Control>
                                                    <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row className="text-center justify-content-center mt-2">
                                            <Col md="12">
                                                <div className="button-wrapper">
                                                    <Button className="btn-fill res-size" type="submit" style={{ backgroundColor: "#3AAB7B", border: "none" }} disabled={isLoading}>
                                                        {isLoading ? "Filtering..." : "Filter"}
                                                    </Button>
                                                    {!isLoading && filtered && (
                                                        <Button className="btn-fill res-size" style={{ backgroundColor: "#3AAB7B", border: "none" }} disabled={isDownloading}>
                                                            <CSVLink
                                                                data={membershipReport}
                                                                filename={"membership_report.csv"}
                                                                onClick={() => {
                                                                    setIsDownloading(true); // Start downloading
                                                                    setTimeout(
                                                                        () => setIsDownloading(false), // Stop downloading
                                                                        1000
                                                                    );
                                                                }}
                                                            >
                                                                {isDownloading ? "Downloading..." : "Download"}
                                                            </CSVLink>
                                                        </Button>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
